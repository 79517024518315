import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import BasketCardItem from "./BasketCardItem.js";
import BasketExpiryTimer from "./BasketExpiryTimer.js";
import Card from "Components/Cardx.js";
import CheckoutService from "Services/CheckoutService.js";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Divider from "@material-ui/core/Divider";
import Flex from "Components/Flex.js";
import IconButton from "Components/IconButton.js";
import String from "Components/String.js";
import Strings from "./BasketCard.strings.json";
import scss from "./BasketCard.module.scss";
import withMobile from "Hoc/withMobile.js";
import {BasketIcon} from "Resources/Icons.js";
import {CheckoutBasketItem as BasketItem, CheckoutBasketService, Localisation, OrderableTypes} from "@hps/hops-sdk-js";
import CheckoutDiscountsUi from "./Discounts/CheckoutDiscountsUi.js";
import Button from "Components/Button.js";
import Routes from "Resources/Routes.json";

/**
 * Basket card
 *
 * Displays the content of the user's basket.
 * 
 * @package HOPS
 * @subpackage Checkout
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class BasketCard extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Is an item being actively modified?
		 *
		 * (I.e. pending network call to update server basket contents.)
		 *
		 * @type {Boolean}
		 */
		modifyingItem: false

	};


	/**
	 * Clear all items in the basket.
	 *
	 * @return {void}
	 */
	handleClearAll = () => CheckoutService.emptyBasket();


	/**
	 * Item removal started/completed.
	 *
	 * @param {Boolean} removing
	 * @return {void}
	 */
	handleRemoving = removing => this.setState({modifyingItem: removing});


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Card
				className={`${((this.props.bp("lg") && this.props.sticky) ? scss.sticky : "")} ${this.props.className}`.trim()}
				headerAction={(!this.props.disableModification && !this.props.noHeader && !!this.props.basket.length && this.renderClearAll())}
				headerLabel={(!this.props.noHeader && this.strings.label)}
				headerIcon={(!this.props.noHeader && BasketIcon)}
				outlined={this.props.inline}>
				{this.renderMain()}
				{this.props.disableModification &&
				<Button
					color="primary"
					label="Edit Basket"
					uri={Routes.basket}
					variant="outlined" />
				}
			</Card>
		);
	}


	/**
	 * Render the actual basket content.
	 * 
	 * @return {ReactNode}
	 */
	renderBasket() {
		return (
			<Flex className={(this.props.invertTotalRowOrder ? scss.invertedTotalRowOrder : undefined)}>
				{
					this.props.basket
						.sort((a, b) => {
							// Sort the list so Delivery Methods always appear at the bottom
							if (a.OrderableType === OrderableTypes.DeliveryMethod) return 1;
							if (b.OrderableType === OrderableTypes.DeliveryMethod) return -1;
							return 0;
						})
						.filter(i => !BasketItem.isInternal(i)).map((item, key) => (
							<BasketCardItem
								disableModification={this.props.disableModification}
								disabled={(this.state.modifyingItem || this.props.basketLoading)}
								discounts={(this.props.basketDiscounts.filter(discount => (discount.ItemClaim === item.Uuid)))}
								item={item}
								key={key}
								noDeliveryMethodWarningText={this.props.noDeliveryMethodWarningText}
								onRemoving={this.handleRemoving}
								pendingSeatSelection={(this.props.seatSelectionValidities?.[item.Uuid] === false)} />
						))
				}
				{this.props.showDiscountUi && <CheckoutDiscountsUi />}
				<Flex className={scss.totalRowContainer}>
					{(!this.props.invertTotalRowOrder && <Divider className={scss.divider} />)}
					<Flex gap={0.5}>
						<Flex
							className={scss.totalRow}
							columnar={true}
							justifyContent="space-between">
							<String
								bold={true}
								str={this.strings.totalDue} />
							<String
								bold={true}
								str={Localisation.formatCurrency(this.basketTotal)} />
						</Flex>
						{(!!this.props.orderVouchers?.length && this.renderTotalWithoutVouchers())}
					</Flex>
					{(!!this.props.basket.length && !isNaN(this.props.basketExpiry) && <BasketExpiryTimer expiry={this.props.basketExpiry} />)}
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render the "clear all" button.
	 * 
	 * @return {ReactNode}
	 */
	renderClearAll() {
		return (
			<IconButton
				defaultStyling={true}
				icon={ClearAllIcon}
				label="Clear All"
				onClick={this.handleClearAll}
				size="small" />
		);
	}


	/**
	 * Render the main section.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		if (!this.props.basket.length) {
			return (
				<Flex alignItems="center" pb={(!this.props.noHeader ? 0.5 : 0)}>
					<String
						centre={true}
						color="error"
						str={this.strings.empty} />
				</Flex>
			);
		}
		else return this.renderBasket();
	}


	/**
	 * Render the total due without vouchers (via main payment method).
	 * 
	 * @return {ReactNode}
	 */
	renderTotalWithoutVouchers() {
		return (
			<Flex
				className={scss.totalRow}
				columnar={true}
				justifyContent="space-between">
				<String str={this.strings.totalDueWithoutVouchers} />
				<String str={Localisation.formatCurrency(this.basketTotalAfterOrderVoucherRedemptions)} />
			</Flex>
		);
	}


	/**
	 * Get the total price of all the items in the basket.
	 *
	 * This does consider the quantity wanted for each item.
	 *
	 * @return {Integer} Pence Inc-VAT; all quantity units of all items
	 */
	get basketTotal() {
		return CheckoutBasketService.getBasketTotal(
			(this.props.basket || []),
			(this.props.basketDiscounts || [])
		);
	}


	/**
	 * Get the total price to pay after order vouchers have been redeemed.
	 *
	 * @return {Integer} Pence
	 */
	get basketTotalAfterOrderVoucherRedemptions() {
		return Math.max(0, (this.basketTotal - this.orderVouchersTotalAvailableBalance));
	}


	/**
	 * Get the total available balance of all applied order vouchers.
	 *
	 * @return {Integer} Pence
	 */
	get orderVouchersTotalAvailableBalance() {
		return (this.props.orderVouchers || []).reduce((a, b) => (a + (b.AvailableBalance)), 0);
	}


	/**
	 * Strings.
	 *
	 * @type {Object}
	 */
	strings = Strings;

}

export default withMobile(BasketCard);
