import {lazy} from "react";
import Routes from "Resources/Routes.json";

export default [
	{
		uri: Routes.account,
		auth: true,
		component: lazy(() => import("Profile/ProfileView.js")),
		inexact: true,
		preserveRkey: true,
		title: "Members' Area",
		description: "Manage your membership."
	},
	{
		uri: "/news",
		auth: true,
		component: lazy(() => import("Profile/ProfileView.js")),
		inexact: true,
		preserveRkey: true,
		title: "News",
		description: "View the latest Members' news."
	},
	{
		uri: Routes.basket,
		component: lazy(() => import("Checkout/BasketView.js")),
		title: "Basket"
	},
	{
		uri: Routes.checkout,
		component: lazy(() => import("Checkout/CheckoutView.js")),
		title: "Checkout"
	},
	{
		uri: `${Routes.memberships}/:membership/renew`,
		component: lazy(() => import("Memberships/MembershipsView.js")),
		auth: true,
		authHeader: "Membership Renewal",
		authCaption: "Please login to renew your membership.",
		feature: "Memberships",
		title: "Memberships",
		description: "Support us by becoming a member."
	},
	{
		uri: `${Routes.memberships}/:membership`,
		component: lazy(() => import("Memberships/MembershipsView.js")),
		feature: "Memberships",
		title: "Memberships",
		description: "Support us by becoming a member."
	},
	{
		uri: Routes.memberships,
		component: lazy(() => import("Memberships/MembershipsView.js")),
		feature: "Memberships",
		title: "Memberships",
		description: "Support us by becoming a member."
	},
	{
		uri: Routes.tickets,
		component: lazy(() => import("Tickets/TicketsView.js")),
		inexact: true,
		preserveRkey: true,
		feature: "Tickets",
		title: "Tickets",
		description: "Purchase tickets for travel and special events."
	},
	{
		uri: Routes.shares,
		component: lazy(() => import("Shares/SharesView.js")),
		inexact: true,
		preserveRkey: true,
		feature: "Shares",
		title: "Shares",
		description: "Purchase shares in a company."
	},
	{
		uri: Routes.preview,
		component: lazy(() => import("F12/PreviewModeView.js")),
		title: "Feature Management",
		description: "Manage available features in your session."
	},
	{
		uri: Routes.vouchers,
		component: lazy(() => import("Vouchers/VouchersView.js")),
		inexact: true,
		preserveRkey: true,
		title: "Vouchers",
		description: "The perfect gift for a friend or family member."
	},
	{
		uri: Routes.giftcards,
		component: lazy(() => import("GiftCards/GiftCardsView.js")),
		inexact: true,
		preserveRkey: true,
		title: "Gift Cards",
		description: "The perfect gift for a friend or family member."
	},
	{
		uri: "/pwr/:token",
		component: lazy(() => import("Login/PwrView.js")),
		publicOnly: true,
		title: "Password Reset",
		description: "Complete your password reset."
	},
	{
		uri: "/verify/email",
		component: lazy(() => import("Login/EmvView.js")),
		title: "Email Validation",
		description: "Validate an email address."
	},
	{
		uri: "/:uri",
		component: lazy(() => import("Index/IndexView404.js")),
		inexact: true,
		title: "Page Not Found",
		description: "Unknown URI."
	},
	{
		uri: Routes.index,
		component: lazy(() => import("Index/IndexView.js"))
	}
];
