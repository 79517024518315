import React from "react";
import Container from "Components/Container.js";
import OrderCompleteBanner from "Checkout/OrderCompleteBanner.js";
import withAuth from "Hoc/withAuth.js";
import {connect} from "react-redux";
import Banner from "Components/Banner";
import UiBannerSeverity from "./UiBannerSeverity";
import Flex from "Components/Flex";

/**
 * UI banners host
 */
const UiBanners = props => {

	const {
		checkoutRedirect,
		org
	} = props;

	const containerStyles = {position: "sticky", top: "9rem", width: "100%", zIndex: 1000};

	return (
		<Container mb={2} mt={-4} style={containerStyles}>
			<Flex gap={0}>
				{org.Banners?.map((banner, key) => (
					<Banner
						key={key}
						str={banner.Message}
						title={banner.Title}
						severity={UiBannerSeverity[banner.Severity]} />
				))
				}
				{checkoutRedirect && <OrderCompleteBanner />}
			</Flex>
		</Container>
	);

};

export default connect(({checkoutRedirect, org}) => ({checkoutRedirect, org}))(withAuth(UiBanners));
