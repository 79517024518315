import React from "react";
import Container from "Components/Container.js";
import Dialog from "./Dialog.js";
import {IconButton, Slide} from "@material-ui/core";
import {Close as CloseIcon} from "@material-ui/icons";

/**
 * Dialog UI intend for an interstitial form screen
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DialogInterstitial extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				DialogContentContainerProps={this.constructor.DialogContentContainerProps}
				DialogContentStyles={this.constructor.DialogContentStyles}
				disabled={this.props.disabled}
				fullWidth={true}
				maxWidth="xs"
				noActions={true}
				noBottomPadding={true}
				onClose={this.props.onClose}
				open={this.props.open}
				title={this.props.title}
				transitionDuration={500}
				TransitionComponent={(this.props.TransitionComponent || Slide)}
				TransitionProps={this.constructor.TransitionProps}>
				<Container
					gridTemplateRows={`${(!this.props.hideCloseButton ? "max-content" : "")} 1fr`.trim()}
					pt={1}>
					{(!this.props.hideCloseButton && this.renderCloseButton())}
					{this.props.children}
				</Container>
			</Dialog>
		);
	}


	/**
	 * Render the close button.
	 *
	 * @return {ReactNode}
	 */
	renderCloseButton() {
		return (
			<div style={this.constructor.stylesCloseBtn}>
				<IconButton
					color="primary"
					disabled={this.props.disabled}
					onClick={this.props.onClose}
					size="small">
					<CloseIcon />
				</IconButton>
			</div>
		);
	}


	/**
	 * `DialogContent` styles
	 * 
	 * @type {Object}
	 */
	static DialogContentStyles = {
		height: "100%",
		paddingBottom: "0rem",
		paddingTop: "0rem"
	};

	/**
	 * `TransitionProps`
	 *
	 * @type {Object}
	 */
	static TransitionProps = {direction: "up"};

	/**
	 * Styles (close button container).
	 * 
	 * @type {Object}
	 */
	static stylesCloseBtn = {
		textAlign: "center"
	};

}

export default DialogInterstitial;
