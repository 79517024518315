import React from "react";
import Container from "Components/Container.js";
import Loadable from "Components/Loadable.js";
import String from "Components/String.js";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";

/**
 * Generic UI view container
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				className={this.props.className}
				columns="100%"
				gap={this.gap}
				justifyContent="center"
				justifyItems={(!this.props.noCentre ? "center" : null)}
				justifySelf="center"
				maxWidth={(this.props.maxWidth || "128rem")}
				px={(!this.props.noPadding ? 1 : 0)}
				singleColumn={true}
				style={this.constructor.styles}
				width={(this.props.containerWidth || "100%")}>
				{((this.props.label || this.props.intro) ? this.renderHeader() : null)}
				<Loadable
					error={this.props.error}
					errorMessage={this.props.errorMessage}
					e404Label={this.props.e404Label}
					e404Uri={this.props.e404Uri}
					loading={this.props.loading}
					onRetry={this.props.onErrorRetry}>
					{this.props.children}
				</Loadable>
			</Container>
		);
	}


	/**
	 * Render the header.
	 * 
	 * @return {ReactNode}
	 */
	renderHeader() {
		return (
			<Container>
				{(this.props.label ? this.renderLabel() : null)}
				{(this.props.intro ? this.renderIntro() : null)}
			</Container>
		);
	}


	/**
	 * Render our intro.
	 *
	 * @return {ReactNode}
	 */
	renderIntro() {
		return (
			<String
				centre={!this.props.noCentre}
				color="textSecondary"
				str={this.props.intro} />
		);
	}


	/**
	 * Render our label.
	 *
	 * @return {ReactNode}
	 */
	renderLabel() {
		return (
			<String
				bold={true}
				centre={!this.props.noCentre}
				str={this.props.label}
				variant="h5" />
		);
	}


	/**
	 * Get the gap spacing multiplier between items.
	 * 
	 * @return {Integer}
	 */
	get gap() {
		return (this.props.gap || 2);
	}


	/**
	 * Styles
	 *
	 * @type {Object}
	 */
	static styles = {marginBottom: rem(2)};

}

export default withMobile(UiView);
