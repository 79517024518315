import Button from "Components/Button";
import RedeemIcon from "@material-ui/icons/Redeem";
import Card from "Components/Card";
import Flex from "Components/Flex";
import String from "Components/String";
import Routes from "Resources/Routes.json";
import PropTypes from "prop-types";

const GiftCardRedeemCard = ({color, message, title, withCard}) => {


	const renderButton = () => {
		return (
			<Button
				color={color}
				label="Redeem a Gift Card"
				size="large"
				startIcon={<RedeemIcon />}
				uri={`${Routes.giftcards}/redeem`}
				variant="outlined" />
		);
	};

	const renderCard = () => {
		return (
			<Card gap={0}>
				<Flex
					alignItems="center"
					columnar={true}
					justifyContent="space-between">
					<String
						color="secondary"
						str={(title ?? "Gift Cards")}
						variant="h6" />
					<RedeemIcon color="secondary" />
				</Flex>
				<Flex alignItems="center">
					<Flex fullWidth={true} pt={0.5}>
						{message && <String
							str={message} />}
						{renderButton()}
					</Flex>
				</Flex>
			</Card>
		);
	};

	return withCard ? renderCard() : renderButton();
};

GiftCardRedeemCard.propTypes = {
	color: PropTypes.string,
	withCard: PropTypes.bool
};

GiftCardRedeemCard.defaultProps = {
	color: "primary",
	withCard: true
};

export default GiftCardRedeemCard;
