import React from "react";
import Container from "Components/Container.js";
import Flex from "Components/Flex.js";
import Link from "Components/Link.js";
import String from "Components/String.js";
import PropTypes from "prop-types";
import scss from "./UiContainer.module.scss";
import withMobile from "Hoc/withMobile.js";

/**
 * UI container
 *
 * A generic inner view container.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiContainer extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				columns={this.props.columnsOuter}
				fullWidth={true}
				gap={(this.useSingleColumnView ? 1 : 2)}
				justifyContent={this.props.justifyContent}
				maxWidth={this.props.maxWidth}
				px={(this.useSingleColumnView ? 0 : 2)}
				singleColumn={this.props.singleColumn}>
				<Flex>
					{(this.props.backLabel ? this.renderBack() : null)}
					<div className={(this.props.headerImg && scss.headerContentContainerImaged)}>
						<Flex>
							<String
								bold={true}
								str={this.props.label}
								variant="h4" />
							<String
								color="textSecondary"
								gap={0.75}
								lineHeight={1.8}
								str={this.props.intro} />
						</Flex>
						{(this.props.headerImg && this.renderImage())}
					</div>
				</Flex>
				{this.props.headerChildren}
				<Container
					alignItems={this.props.alignItems}
					columnar={!this.useSingleColumnView}
					columns={(this.props.columns || (!this.props.invertColumnOrder ? "auto 36rem" : "36rem auto"))}
					fullWidth={true}
					gap={(this.useSingleColumnView ? 1 : 2)}
					singleColumn={(this.useSingleColumnView || this.props.singleColumn)}>
					{this.props.children}
				</Container>
			</Container>
		);
	}


	/**
	 * Render the back link.
	 *
	 * @return {ReactNode}
	 */
	renderBack() {
		return (
			<Link
				label={this.props.backLabel}
				onClick={this.props.backClick}
				startArrow={true}
				uri={this.props.back} />
		);
	}


	/**
	 * Render the image.
	 * 
	 * @return {ReactNode}
	 */
	renderImage() {
		return (
			<img
				alt={this.props.headerImgAlt}
				className={scss.headerImg}
				src={this.props.headerImg} />
		);
	}


	/**
	 * Get whether to use the single column view layout.
	 * 
	 * @return {Boolean}
	 */
	get useSingleColumnView() {
		return !this.props.bp(this.props.singleColumnBreakpoint);
	}


	static propTypes = {
		singleColumnBreakpoint: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false])
	};

	static defaultProps = {
		singleColumnBreakpoint: "md"
	};

}

export default withMobile(UiContainer);
