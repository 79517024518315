import React from "react";
import Flex from "Components/Flex.js";
import Link from "Components/Link.js";
import Loader from "Components/Loaderx.js";
import String from "Components/String.js";

/**
 * Loadable component
 *
 * A component with loading and error states.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Loadable extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (this.props.loading) return this.renderLoader();
		else if (this.props.error === 404) return this.render404();
		else if (this.props.error) return this.renderError();
		else return this.props.children;
	}


	/**
	 * Render the 404 state.
	 *
	 * @return {ReactNode}
	 */
	render404() {
		return this.constructor.renderSelf(
			<>
				<String bold={true} str="Page Not Found" variant="h5" />
				<String lineHeight={4} str="Please check the link you've used." />
				<Link endArrow={!!this.props.e404Label} label={(this.props.e404Label || "Home")} uri={(this.props.e404Uri || "/")} />
			</>
		);
	}


	/**
	 * Render the error state.
	 *
	 * @return {ReactNode}
	 */
	renderError() {
		return this.constructor.renderSelf(
			<>
				<String color="error" str={(this.props.errorMessage || "Error.")} />
				{(this.props.onRetry && <Link endArrow={this.props.retryEndArrow} label={(this.props.retryLabel || "Retry")} onClick={this.props.onRetry} />)}
			</>
		);
	}


	/**
	 * Render the loader.
	 * 
	 * @return {ReactNode}
	 */
	renderLoader() {
		return this.constructor.renderSelf(
			<Loader size={this.props.loaderSize} />
		);
	}


	/**
	 * Render content directly.
	 *
	 * @param {ReactNode} content
	 * @return {ReactNode}
	 */
	static renderSelf(content) {
		return (
			<Flex alignItems="center" fullWidth={true} gap={0.5}>
				{content}
			</Flex>
		);
	}

}

export default Loadable;
