import React, {useEffect, useState} from "react";
import AppService from "Services/AppService.js";
import Flex from "Components/Flex.js";
import Container from "Components/Container.js";
import Image from "Components/Image.js";
import Link from "Components/Link";
import Navigator from "App/Navigator.js";
import Routes from "Resources/Routes.json";
import withMobile from "Hoc/withMobile.js";
import withOrg from "Hoc/withOrg.js";
import {withRouter} from "react-router-dom";
import scss from "./UiBar.module.scss";
import throttle from "lodash.throttle";
import withCheckoutBasket from "Hoc/withCheckoutBasket.js";
import {AppBar, Badge, CardActionArea, Toolbar} from "@material-ui/core";
import {CheckoutBasketItem as BasketItem} from "@hps/hops-sdk-js";
import * as icons from "Resources/Icons.js";
import IconButton from "Components/IconButton";
import Button from "Components/Button";

/**
 * UI bar
 */
const UiBar = ({checkoutBasket, isMobile, location, org}) => {

	const collapsedScrollPosition = () => {
		return (window.scrollY > 120);
	};

	const [collapsed, setCollapsed] = useState(collapsedScrollPosition());

	const height = (isMobile || collapsed) ? "9rem" : "12rem";

	const styles = {
		height,
		minHeight: height,
		transition: "all 0.2s ease-out",
		transitionProperty: "height, min-height"
	};

	const imageStyles = {
		height: "100%",
		justifyContent: "center"
	};

	const imageStylesInner = {
		height: "100%",
		objectFit: "contain"
	};


	useEffect(() => {

		const handleScroll = throttle(() => {
			setCollapsed(collapsedScrollPosition());
		}, 150);

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};

	}, []);

	const handleLogoClick = () => {
		Navigator.navigate(Routes.index);
	};

	return (
		<AppBar className={scss.AppBar} position="fixed" style={styles}>
			<Flex>
				<Toolbar className={scss.Toolbar} disableGutters={true} style={styles}>
					<Container
						alignContent="center"
						alignItems="center"
						className={scss.Container}
						columnar={true}
						gridTemplateRows="70%"
						height="100%"
						justifyContent="space-between"
						width="100%">
						<CardActionArea
							className={scss.LogoButton}
							onClick={handleLogoClick}>
							<Image
								alt={org.NameTrade}
								imgStyles={imageStylesInner}
								noError={true}
								noLoader={true}
								src={AppService.getRegistrationLogo()}
								style={imageStyles} />
						</CardActionArea>
						<Flex
							alignItems="center"
							className={(!isMobile ? scss.IconButtonContainer : scss.IconButtonContainer_small)}
							columnar={true}
							justifyContent="flex-end">
							<Link uri={"/"}>
								<IconButton
									classes={{root: scss.NavLink}}
									defaultStyling={true}
									icon={icons.HomeIcon}
									label="Home" />
							</Link>
							{!isMobile && org?.NavigationLinks?.map((link, key) => (
								<Link key={key} uri={link.Uri}>
									<Button classes={{root: (location.pathname.startsWith(link.Uri) ? scss.NavLinkActive : scss.NavLink)}} label={link.Label} variant="text" />
								</Link>
							))}
							<Badge
								badgeContent={checkoutBasket?.filter(i => !BasketItem.isInternal(i))?.reduce((n, {Quantity}) => n + Quantity, 0)}
								className={scss.CheckoutBadge}
								color="error">
								<Link uri={Routes.basket}>
									<IconButton
										classes={{root: (location.pathname.startsWith(Routes.basket) ? scss.NavLinkActive : scss.NavLink)}}
										defaultStyling={true}
										icon={icons.BasketIcon}
										label="Basket" />
								</Link>
							</Badge>
							<Link uri={Routes.account}>
								<IconButton
									classes={{root: (location.pathname.startsWith(Routes.account) ? scss.NavLinkActive : scss.NavLink)}}
									defaultStyling={true}
									icon={icons.AccountIcon}
									label="Members' Area" />
							</Link>
						</Flex>
					</Container>
				</Toolbar>
			</Flex>
		</AppBar>
	);

};

export default withCheckoutBasket(withMobile(withOrg(withRouter(UiBar))));
